<template>
    <div>
      <v-bottom-navigation
      
        class="d-md-none d-flex mobile-navigation-bottom justify-space-between"
        color="primary"
        fixed
      >
      
        <v-btn to="/">
          <span class="">{{ $t("首页") }}</span>
          <v-icon>mdi-home-outline</v-icon>
        </v-btn>
        <v-btn to="/order/fanli-order">
          <span>{{ $t("订单") }}</span>
          <v-icon>mdi-file-document-outline</v-icon>
        </v-btn>
        <v-btn to="/user-index">
          <span>{{ $t("我的") }}</span>
          <v-icon>mdi-account-circle-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
  </div>
</template>

<script>
  export default {
    data: () => ({ value: 1 }),

    computed: {
    //   color () {
    //     switch (this.value) {
    //       case 0: return 'blue-grey'
    //       case 1: return 'teal'
    //       case 2: return 'brown'
    //       case 3: return 'indigo'
    //       default: return 'blue-grey'
    //     }
    //   },
    },
  }
</script>
<style lang="scss">
    .mobile-navigation-bottom {
        bottom: 0;
        z-index: 100 !important;
    }
</style>
